import React, { useState } from "react";
import Page from "~/components/Page";
import Text from "~/components/Text";
import "./HomePage.scss";
import dateDifference from "~/utils/helpers/dateDifference";
import ClassList from "~/utils/helpers/ClassList";
import useTimeout from "~/hooks/useTimeout";
import useIsClient from "../../hooks/useIsClient";

const HomePage = () => {
  const [renderDots, setRenderDots] = useState(true);
  const dateDiff = dateDifference(new Date(), new Date("2022-3-30"));

  const { isClient } = useIsClient();

  useTimeout(
    () => {
      setRenderDots(!renderDots);
    },
    1000,
    []
  );
  return (
    <Page
      name="Home"
      seo={{ title: "Home" }}
      className="d-flex align-center justify-center flex-1"
    >
      {/* <div className="Circle" /> */}
      {isClient && (
        <Text className="time">
          {dateDiff.d}d{" "}
          <Text
            as="span"
            className={ClassList.join(
              "time--dots",
              ClassList.bind({ active: renderDots })
            )}
          >
            :
          </Text>{" "}
          {dateDiff.h}h{" "}
          <Text
            as="span"
            className={ClassList.join(
              "time--dots",
              ClassList.bind({ active: renderDots })
            )}
          >
            :
          </Text>{" "}
          {dateDiff.m}m
        </Text>
      )}
    </Page>
  );
};

export default HomePage;
