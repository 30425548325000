function dateDifference(str1: string, str2: string) {
  const diff = Date.parse(str2) - Date.parse(str1);
  return isNaN(diff)
    ? NaN
    : {
        diff: diff,
        ms: Math.floor(diff % 1000).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        s: Math.floor((diff / 1000) % 60).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        m: Math.floor((diff / 60000) % 60).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        h: Math.floor((diff / 3600000) % 24).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        d: Math.floor(diff / 86400000).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
      };
}

export default dateDifference;
